import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Button,
  Card,
  CardContent,
  Grid,
  Box,
  Stack,
  Chip,
  Avatar,
} from "@mui/material";
import Gama from "./images/gama.png";
import Landing from "./images/landing.jpg";
import {
  MouseSimple,
  Lock,
  Coins,
  Lightning,
  ShieldCheckered,
} from "phosphor-react";

export const systemFonts =
  '-apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu';

const archivo = '"Archivo Black", ' + systemFonts;

function FeatureCard(props: any) {
  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card
        sx={{
          boxShadow: "none",
          background: "#25A881",
          padding: "8px",
          height: "100%",
          borderRadius: "20px",
        }}
      >
        <CardContent>
          <Avatar
            sx={{
              width: "50px",
              height: "50px",
              background: "#2ABF92",
              color: "#E8FAF5",
              marginBottom: "8px",
            }}
          >
            {props.icon}
          </Avatar>
          <Typography
            variant="h5"
            color="#E8FAF5"
            fontSize={16}
            sx={{
              fontFamily: archivo,
            }}
            textAlign="left"
          >
            {props.title}
          </Typography>
          <Typography variant="subtitle2" color="white" textAlign="left">
            {props.description}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
}

function App() {
  return (
    <>
      <AppBar
        position="sticky"
        sx={{
          boxShadow: "none",
          backgroundColor: "#25A88140",
          backdropFilter: "blur(20px)",
        }}
      >
        <Container maxWidth="md">
          <Toolbar>
            <Box sx={{ flexGrow: 1 }}>
              <Stack direction="row" spacing={2} alignItems="center">
                <Typography
                  variant="h6"
                  component="div"
                  fontWeight="bold"
                  color="black"
                >
                  Odyne Wallet
                </Typography>
                <Chip
                  label="Private beta"
                  size="small"
                  sx={{
                    background: "#16644D",
                    color: "white",
                  }}
                />
              </Stack>
            </Box>
            <Button
              variant="contained"
              sx={{
                boxShadow: "none",
                background: "#2ABF92",
                color: "#E8FAF5",
                textTransform: "none",
                "&:hover": {
                  boxShadow: "none",
                  background: "#2ABF92",
                  color: "white",
                },
                borderRadius: "24px",
              }}
              component="a"
              href="https://app.odyne.co"
              target="_blank"
            >
              App
            </Button>
          </Toolbar>
        </Container>
      </AppBar>
      <br />
      <br />
      <div
        style={{
          background: "url(./fox.png), url(./uni.png)",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "bottom left, bottom right",
          backgroundSize: "20%, 20%, 20%, 20%",
          margin: "auto",
          maxWidth: "1100px",
        }}
      >
        <Container maxWidth="md">
          <Container maxWidth="sm">
            <img
              src={Landing}
              width="100%"
              alt="Landing"
              style={{
                borderRadius: "8px",
              }}
            />
            <br />
            <br />
            <Typography
              variant="h5"
              fontWeight="bold"
              sx={{
                fontFamily: archivo,
              }}
            >
              Best way to access crypto
            </Typography>
            <Typography fontWeight="medium">
              Whether you have years of experience or just getting started,
              Odyne Wallet is the right fit for you thanks to simplicity and
              security provided.
            </Typography>
          </Container>
          <br />
          <br />
          <br />
          <Grid container spacing={2} justifyContent="center">
            <FeatureCard
              title="Easy to use"
              description="Odyne Wallet is designed to be easy to use and understand. It is built with simplicity in mind."
              icon={<MouseSimple size={24} weight="bold" color="#E8FAF5" />}
            />
            <FeatureCard
              title="Secure"
              description="Odyne Wallet is designed to be secure and protect your funds without compromising on usability."
              icon={<Lock size={24} weight="bold" color="#E8FAF5" />}
            />
            <FeatureCard
              title="Free"
              description="Odyne Wallet is free to use and will always be free to use. No hidden fees."
              icon={<Coins size={24} weight="bold" color="#E8FAF5" />}
            />
            <FeatureCard
              title="Lightning fast"
              description="With global servers and a lightning fast API, Odyne Wallet is the fastest way to access crypto."
              icon={<Lightning size={24} weight="bold" color="#E8FAF5" />}
            />
            <FeatureCard
              title="Zero knowledge"
              description="Odyne Wallet is designed to be zero knowledge. We do not store any of your data or funds."
              icon={<ShieldCheckered size={24} weight="bold" color="#E8FAF5" />}
            />
          </Grid>
        </Container>
        <br />
        <br />
        <br />
      </div>
      <br />
      <br />
      <br />
      <Container maxWidth="md">
        <Container maxWidth="sm">
          <Typography
            variant="h5"
            fontWeight="bold"
            sx={{
              fontFamily: archivo,
            }}
          >
            Built by crypto enthusiasts
          </Typography>
          <Typography sx={{ marginTop: "8px" }}>
            Odyne Wallet is built by crypto enthusiasts who are passionate about
            the space and want to make it more accessible to everyone.
          </Typography>
        </Container>
        <br />
        <br />
        <br />
        <Container maxWidth="sm">
          <Typography
            variant="h5"
            fontWeight="bold"
            sx={{
              fontFamily: archivo,
              background: "linear-gradient(90deg, #0096D2 2.75%, #A0097F 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              backgroundClip: "text",
              textFillColor: "transparent",
            }}
          >
            Join the private beta
          </Typography>
          <br />
          <Typography>
            We are looking for crypto enthusiasts to help us build the best
            crypto wallet in the world. Join the private beta and be the first
            to try Odyne Wallet.
          </Typography>
          <br />
          <br />
        </Container>
        <Container maxWidth="md">
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={6} sm={4}>
              <Typography
                variant="subtitle2"
                sx={{
                  fontFamily: archivo,
                }}
              >
                Email
              </Typography>
              <Typography variant="h5" fontSize={17} fontWeight="medium">
                hello@odyne.co
              </Typography>
              <br />
              <br />
              <a href="https://gamalabs.xyz" target="_blank" rel="noreferrer">
                <img src={Gama} width="128px" height="auto" alt="Gama Labs" />
              </a>
            </Grid>
          </Grid>
        </Container>
      </Container>
      <br />
      <br />
      <br />
    </>
  );
}

export default App;
