import React from "react";
import ReactDOM from "react-dom/client";
import App, { systemFonts } from "./App";
import { CssBaseline, createTheme, ThemeProvider } from "@mui/material";

const theme = createTheme({
  palette: {
    background: {
      default: "#E8FAF5",
    },
    text: {
      primary: "#41463D",
    },
  },
  typography: {
    fontFamily: "Inter" + systemFonts,
    h6: {
      fontWeight: 600,
      fontFamily: "Space Grotesk" + systemFonts,
    },
    h5: {
      fontWeight: 600,
    },
    allVariants: {
      textAlign: "center",
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </React.StrictMode>
);
